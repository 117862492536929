import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { navigateTo } from 'gatsby-link'

import Toro from '../assets/img/toro.png'
import Irritrol from '../assets/img/irritrol.png'
import Rainbird from '../assets/img/rainbird.png'
import Hunter from '../assets/img/hunter.png'
import CyberRain from '../assets/img/cyber-rain.jpg'
import EzFlo from '../assets/img/ez-flo.png'
import Rachio from '../assets/img/rachio.png'
import Econeau from '../assets/img/econeau.png'
import Home from '../assets/img/home.jpg'

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  Row,
} from 'reactstrap'

import {
  CardAuthorIcon,
  Button,
  Statistics,
} from '../components'

import SEO from '../components/seo'

class HomeIndex extends React.Component {
  render() {    
    const seoData = {
      title: "404",
      description:
        "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation à Montréal, Laval, Rive-Nord, Laurentides et toute la province de Québec!",
      cover: Home,
      path: '',
      schemaOrg: ''
    }

    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <Row>
            <h1>
              Page introuvable!
            </h1>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Card className="card-user">
                <CardBody>
                  <CardAuthorIcon
                    link="/services/irrigation-residentielle"
                    icon="flat-home-page"
                    color="white"
                    avatarAlt="..."
                    title="Résidentiel"
                    description="Un système d’irrigation automatique fait partie de l’aménagement paysager : voyez toutes les options s’offrant à vous."
                  />
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    color="primary"
                    size="md"
                    round
                    onClick={() => navigateTo('/services/irrigation-residentielle')}
                  >
                    En savoir plus
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Card className="card-user">
                <CardBody>
                  <CardAuthorIcon
                    link="/services/irrigation-commerciale"
                    icon="flat-building"
                    color="white"
                    avatarAlt="..."
                    title="Commercial"
                    description="L’image d’une entreprise est bien souvent la première évaluation de votre clientèle : voyez toutes les options s’offrant à vous."
                  />
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    color="primary"
                    size="md"
                    round
                    onClick={() => navigateTo('/services/irrigation-commerciale')}
                  >
                    En savoir plus
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Card className="card-user">
                <CardBody>
                  <CardAuthorIcon
                    link="/services/irrigation-municipale"
                    icon="flat-university"
                    color="secondary"
                    avatarAlt="..."
                    title="Municipal"
                    description="Que ce soit un parc, un terrain sportif ou même des jardins, le besoin des plantes est le même : voyez toutes les options s’offrant à vous."
                  />
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    color="primary"
                    size="md"
                    round
                    onClick={() => navigateTo('/services/irrigation-municipale')}
                  >
                    En savoir plus
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Card className="card-user">
                <CardBody>
                  <CardAuthorIcon
                    link="/services/irrigation-toits-vert"
                    icon="flat-greenhouse"
                    color="secondary"
                    avatarAlt="..."
                    title="Toîts Vert"
                    description="La toiture végétale est une solutions belles et les plus écologiques à la pollution : voyez toutes les options s’offrant à vous."
                  />
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    color="primary"
                    size="md"
                    round
                    onClick={() => navigateTo('/services/irrigation-toits-vert')}
                  >
                    En savoir plus
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <Card className="card-stats">
                <CardHeader>
                  <CardTitle>Nos marques</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12} md={3} style={{paddingTop: 20}}>
                      <Link title="Rachio" to="/produits/rachio">
                        <Statistics icon={Rachio} alt="Rachio" />
                      </Link>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link title="Toro" to="/produits/toro">
                        <Statistics icon={Toro} alt="Toro"/>
                      </Link>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link title="Irritrol" to="/produits/irritrol">
                        <Statistics icon={Irritrol} alt="Irritrol"/>
                      </Link>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link title="Rainbird" to="/produits/rainbird">
                        <Statistics icon={Rainbird} alt="Rainbird"/>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={3}>
                      <Link title="Hunter" to="/produits/hunter">
                        <Statistics icon={Hunter} alt="Hunter"/>
                      </Link>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link title="EzFlo" to="/produits/ezflo">
                        <Statistics icon={EzFlo} alt="EzFlo"/>
                      </Link>
                    </Col>
                    <Col xs={12} md={3} style={{paddingTop: 10}}>
                      <Link title="Éconeau" to="/produits/econeau">
                        <Statistics icon={Econeau} alt="Éconeau"/>
                      </Link>
                    </Col>
                    <Col xs={12} md={3}>
                      <Link title="Cyber Rain" to="/produits/cyber-rain">
                        <Statistics icon={CyberRain} alt="Cyber Rain"/>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default HomeIndex

export const Page404Query = graphql`
  query Page404Query {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
  }
`
